@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  text-decoration: none;
}

p {
  font-size: 2vh;
}

h1 {
  font-size: 2.5vh;
}

.carouselBg {
  background-color: rgba(0, 0, 0, .3);
}

.global-class-name {
  color: black;
}

.navbar {
  min-width: 100vh;
  background-color: red;
  min-height: 10vh;

}

.page-container {
  position: relative;
  min-height: 100vh;
}


.gradient-bg {
  /* height: 30vh; */
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #c2400d, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}


@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}